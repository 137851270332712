<template>
	<div class="test">
		<div class="page_1">
			<PageNav :show_num="4" />
			<!-- <div class="page_1_center_box">
				<div class="cen-text">
					PRODUCTS
				</div>
				<div class="cen-msg">
					SZZT is the only independent terminal one-stop delivery solution
					<br />
					provider in the world from appearance design, structural design,
					<br />
					component customization, cabinet production, complete machine
					<br />
					assembly, software customization to operation and maintenance
					platform.
				</div>
			</div> -->
		</div>
		<div class="page_2">
			<div class="page_2_1">
				<div class="page_2_1_top">
					<div class="menu-title-class" v-for="(item,index) in device_list" :key="index"
						@click="change_type_1(item.id)">
						<span
							:style="acctive_cata_id === item.id ? 'border-bottom: 2px solid #f09323;font-size: 20px;font-weight:600;' : ''">{{ item.name }}</span>
					</div>
				</div>


			</div>
		</div>
		<div class="page_3">
			<div class="page_2_1_bot">
				<div class="prod-item" v-for="(item,index) in show_device_type" :key="index"
					@mouseenter="change_type_2(item.id)">
					<div class="prode-type">
						{{ item.name }}
					</div>
					<!-- <div class="prode-msg">
						Integrated barcode scanning, payment, and printing functions to help merchants meet the
						personalized needs and realize various services such as acquiring, printing, & store management.
					</div> -->
					<div class="device-list">
						<div class="device-class" v-for="(device,indexs) in item.products" :key="indexs"
							@click="get_device_detail(device)">
							<div class="device-img">
								<img :src="device.img" alt="" />
							</div>
							<div class="device-name">
								{{ device.name }}
							</div>
						</div>


					</div>
				</div>
			</div>
		</div>

		<PageBot :show_num="4" />
		<el-backtop class="back_up" target="" :bottom="100" :visibility-height="620" :right="10">
			<svg class="icon icon_xiangshang" aria-hidden="true">
				<use xlink:href="#icon-xiangshang"></use>
			</svg>
		</el-backtop>
	</div>
</template>

<script>
	import PageNav from "@/components/PageNav.vue"
	import PageBot from "@/components/PageBottom.vue"
	import Swiper from "swiper";
	import "swiper/css/swiper.min.css";
	import {
		resetRem
	} from '@/all_js/all';
	import {
		clickEffect
	} from "@/all_js/click";
	export default {
		name: 'about',
		components: {
			PageBot,
			PageNav
		},

		watch: {

		},

		mounted() {

		},
		data() {
			return {
				acctive_cata_id: 0,
				acctive_type_id: 10,
				show_device_type: [],
				show_device_device: [],
				device_list: [],

			}
		},
		created() {
			// resetRem(); // 根据1920尺寸设置rem，1rem=100px
			// clickEffect(); // 引入鼠标点击特效
			this.init_data()
		},
		methods: {
			init_data() {
				let res = [{
						"id": "1763443968744308737",
						"name": "POS",
						"weight": 0,
						"children": [{
								"id": "1763449016777916417",
								"name": "Smart POS",
								"weight": 0,
								"products": [{
										"id": "1763463658535272449",
										"name": "KS8226",
										"description": null,
										"intro": null,
										"img": require('../../assets/images/products/KS8226-M.png'),
										"weight": 0
									},
									{
										"id": "1769634300863442946",
										"name": "KS8223",
										"description": null,
										"intro": null,
										"img": require('../../assets/images/products/KS8223-M.png'),
										"weight": 0
									}
								]
							},
							{
								"id": "1763448913581260801",
								"name": "Classic POS",
								"weight": 0,
								"products": [{
									"id": "1763458975557173250",
									"name": "i90",
									"description": null,
									"intro": null,
									"img": require('../../assets/images/products/I90-M.png'),
									"weight": 0
								}]
							}
						]
					},
					{
						"id": "1769611078691016706",
						"name": "ECR",
						"weight": 0,
						"children": [{
								"id": "1769625492539731969",
								"name": "Desktop ECR",
								"weight": 0,
								"products": [{
									"id": "1769634619781541890",
									"name": "ZT8648",
									"description": null,
									"intro": null,
									"img": require('../../assets/images/products/ZT8648-M.png'),
									"weight": 0
								}]
							},
							{
								"id": "1769611969489883138",
								"name": "Accessaries",
								"weight": 0,
								"products": [{
										"id": "8648646",
										"name": "ZTS-HR22",
										"description": null,
										"intro": null,
										"img": require('../../assets/images/products/ZTS-HR22-M.png'),
										"weight": 0
									},
									{
										"id": "1516486",
										"name": "ZTS-FR20",
										"description": null,
										"intro": null,
										"img": require('../../assets/images/products/ZTS-FR20-M.png'),
										"weight": 0
									},
									{
										"id": "386446486",
										"name": "ZTS-CPS70",
										"description": null,
										"intro": null,
										"img": require('../../assets/images/products/ZTS-CPS70-M.png'),
										"weight": 0
									}
								]
							}
						]
					},
					{
						"id": "1763444826240401410",
						"name": "QR Terminals",
						"weight": 0,
						"children": [{
								"id": "1769612181889437698",
								"name": "QR Speaker",
								"weight": 0,
								"products": [{
										"id": "1770347105424515074",
										"name": "ZT8303",
										"description": null,
										"intro": null,
										"img": require('../../assets/images/products/KS8303-M.png'),
										"weight": 0
									},
									{
										"id": "1770347165465976833",
										"name": "ZT8309",
										"description": null,
										"intro": null,
										"img": require('../../assets/images/products/ZT8309-M.png'),
										"weight": 0
									}
								]
							}, {
								"id": "1769612244627836929",
								"name": "QR POS",
								"weight": 0,
								"products": [{
									"id": "1769635081465360386",
									"name": "ZT260",
									"description": null,
									"intro": null,
									"img": require('../../assets/images/products/ZT260-M.png'),
									"weight": 0
								}]
							},

							{
								"id": "1769612520285884417",
								"name": "QR Reader",
								"weight": 0,
								"products": [{
										"id": "8648646",
										"name": "ZTS-HR22",
										"description": null,
										"intro": null,
										"img": require('../../assets/images/products/ZTS-HR22-M.png'),
										"weight": 0
									},
									{
										"id": "1516486",
										"name": "ZTS-FR20",
										"description": null,
										"intro": null,
										"img": require('../../assets/images/products/ZTS-FR20-M.png'),
										"weight": 0
									},
								]
							}
						]
					},
					{
						"id": "1763444904267038721",
						"name": "Kiosk",
						"weight": 0,
						"children": [
							{
								"id": "1769612871638536193",
								"name": "Freestanding",
								"weight": 0,
								"products": [{
										"id": "177035022031515645",
										"name": "ZT2220",
										"description": null,
										"intro": null,
										"img": require('../../assets/images/products/ZT2220-M.png'),
										"weight": 0
									},
									{
										"id": "17703502241515645",
										"name": "ZT2880",
										"description": null,
										"intro": null,
										"img": require('../../assets/images/products/ZT2880-M.png'),
										"weight": 0
									},
									{
										"id": "1568235476878411",
										"name": "ZT2618",
										"description": null,
										"intro": null,
										"img": require('../../assets/images/products/ZT2618-M.png'),
										"weight": 0
									}
								]
							},
							{
								"id": "1769612621167284225",
								"name": "Desktop",
								"weight": 0,
								"products": [{
										"id": "1769635277591015425",
										"name": "ZT8669",
										"description": null,
										"intro": null,
										"img": require('../../assets/images/products/ZT8669-M.png'),
										"weight": 0
									},
									{
										"id": "1770349934927159298",
										"name": "KS8123Plus",
										"description": null,
										"intro": null,
										"img": require('../../assets/images/products/KS8123Plus-M.png'),
										"weight": 0
									}
								]
							},
							{
								"id": "1769612741761912833",
								"name": "Wall-mounted",
								"weight": 0,
								"products": [{
									"id": "1770346671842533377",
									"name": "ZT8669Plus",
									"description": null,
									"intro": null,
									"img": require('../../assets/images/products/ZT8669Plus-M.png'),
									"weight": 0
								}]
							},
							{
								"id": "1769625951597916162",
								"name": "STM",
								"weight": 0,
								"products": [{
									"id": "1770346946275844097",
									"name": "ZT2214",
									"description": null,
									"intro": null,
									"img": require('../../assets/images/products/ZT2214-M.png'),
									"weight": 0
								}]
							},
							{
								"id": "1769612929905807361",
								"name": "ATM / VTM",
								"weight": 0,
								"products": [{
										"id": "1770350153702055937",
										"name": "ZT6240",
										"description": null,
										"intro": null,
										"img": require('../../assets/images/products/ZT6240-M.png'),
										"weight": 0
									},
									{
										"id": "1770350220315992066",
										"name": "ZT6250",
										"description": null,
										"intro": null,
										"img": require('../../assets/images/products/ZT6250-M.png'),
										"weight": 0
									}
								]
							},
							
						]
					},
					{
						"id": "1763444946105221122",
						"name": "Encrypting PIN Pad",
						"weight": 0,
						"children": [{
								"id": "1769626295413403649",
								"name": "PCI",
								"weight": 0,
								"products": [{
										"id": "1770345997096460289",
										"name": "ZT588F",
										"description": null,
										"intro": null,
										"img": require('../../assets/images/products/ZT588F-M.png'),
										"weight": 0
									},
									{
										"id": "1770346087148167169",
										"name": "ZT598L",
										"description": null,
										"intro": null,
										"img": require('../../assets/images/products/ZT598L-M.png'),
										"weight": 0
									},
									{
										"id": "1770346167443922946",
										"name": "ZT598M",
										"description": null,
										"intro": null,
										"img": require('../../assets/images/products/ZT598M-M.png'),
										"weight": 0
									},
									{
										"id": "1770346312671698946",
										"name": "ZT598B",
										"description": null,
										"intro": null,
										"img": require('../../assets/images/products/ZT598B-M.png'),
										"weight": 0
									},
									// {
									// 	"id": "1770346246070345730",
									// 	"name": "ZT598H",
									// 	"description": null,
									// 	"intro": null,
									// 	"img": require('../../assets/images/products/ZT598H-M.png'),
									// 	"weight": 0
									// }
								]
							},
							{
								"id": "1769626339780751362",
								"name": "non-PCI",
								"weight": 0,
								"products": [{
										"id": "1770346400227794945",
										"name": "ZT599NM",
										"description": null,
										"intro": null,
										"img": require('../../assets/images/products/ZT599NM-M.png'),
										"weight": 0
									},
									{
										"id": "1770346473745555457",
										"name": "ZT599N",
										"description": null,
										"intro": null,
										"img": require('../../assets/images/products/ZT599N-M.png'),
										"weight": 0
									}
								]
							}
						]
					},
					{
						"id": "1763444865004158978",
						"name": "PDA",
						"weight": 0,
						"children": [{
							"id": "1769625643379486721",
							"name": "Industrial PDA",
							"weight": 0,
							"products": [{
									"id": "1769635591522086914",
									"name": "ZT8211",
									"description": null,
									"intro": null,
									"img": require('../../assets/images/products/ZT8211-M.png'),
									"weight": 0
								},
								{
									"id": "1769635705569406978",
									"name": "ZT8212",
									"description": null,
									"intro": null,
									"img": require('../../assets/images/products/ZT8212-M.png'),
									"weight": 0
								},
								{
									"id": "1769635838264602625",
									"name": "ZT8213",
									"description": null,
									"intro": null,
									"img": require('../../assets/images/products/ZT8213-M.png'),
									"weight": 0
								}
							]
						}]
					},
					{
						"id": "1763444768031850498",
						"name": "Fiscal Devices",
						"weight": 0,
						"children": [{
							"id": "1769627035871637506",
							"name": "Fiscal Device",
							"weight": 0,
							"products": [{
									"id": "1770345684998299650",
									"name": "ZT8350",
									"description": null,
									"intro": null,
									"img": require('../../assets/images/products/ZT8350-M.png'),
									"weight": 0
								},
								{
									"id": "1770345777004552194",
									"name": "ZT8351",
									"description": null,
									"intro": null,
									"img": require('../../assets/images/products/ZT8351-M.png'),
									"weight": 0
								}
							]
						}]
					},

				]
				this.device_list = res;
				// this.device_list = res.data
				this.show_device_type = this.device_list[0].children
				this.show_device_device = this.show_device_type[0].products
				this.acctive_cata_id = this.device_list[0].id
				this.acctive_type_id = this.show_device_type[0].id
			},
			get_device_detail(item) {
				// console.log(item)
				this.$router.push({
					path: '/' + item.name,
				})
				// if (item.name == "KS8226") {
				// 	this.$router.push({
				// 		path: '/' + item.name,
				// 	})
				// }
				// getDeviceDetail(item.name).then(res=>{
				// 	console.log(res.data)
				// })
			},
			change_type_1(id) {
				this.acctive_cata_id = id;
				for (var i = 0; i < this.device_list.length; i++) {
					if (this.device_list[i].id === id) {
						this.show_device_type = this.device_list[i].children
						this.show_device_device = this.device_list[i].children[0].children
						this.acctive_type_id = this.device_list[i].children[0].id;
					}
				}
			},
			change_type_2(id) {
				this.acctive_type_id = id;
				for (var i = 0; i < this.show_device_type.length; i++) {
					if (this.show_device_type[i].id === id) {
						this.show_device_device = this.show_device_type[i].children
					}
				}
			},
			go_contact() {
				this.$router.push({
					path: '/contact',
					query: {
						id: '123456'
					},

				})
			},
		}
	}
</script>
<style scoped lang="less">
	* {
		margin: 0;
		padding: 0;
		// transition: all 1s; // 设置动画效果
	}

	li {
		list-style-type: none;
	}

	.page_1 {
		width: 100%;
		height: 580px;
		object-fit: cover; // 保持图片缩放时候不变形
		background-image: url("../../assets/images/products/head1.png");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
	}


	.page_1_center_box {
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.cen-text {
			margin-left: 15%;
			font-size: 60px;
			margin-top: 120px;
		}

		.cen-line {
			width: 72px;
			height: 4px;
			margin-top: 30px;
			margin-left: 15%;
			background-color: #FFFFFF;
		}


		.cen-msg {
			margin-top: 10px;
			margin-left: 15%;
			font-size: 18px;
			line-height: 30px;
		}


	}








	.back_up {
		height: 40px;
		width: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 30px;
		background-color: #49b1f5;

		.icon_xiangshang {
			height: 30px;
			width: 30px;
			border-radius: 30px;
		}
	}

	.back_up:hover {
		background-color: #49b1f5;
	}
</style>
<style scoped lang="less">
	// @media only screen and (min-width:1371px) {}

	.page_2 {
		background: #FFFFFF;

		.page_2_1 {
			width: 1360px;
			margin: 0 auto;
		}

		.page_2_1_top {
			height: 100px;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.menu-title-class {
				cursor: pointer;
				font-size: 16px;
				color: #4e4e4e;
			}


		}



	}

	.page_3 {
		background: #FAFAFA;
		padding-bottom: 100px;

		.page_2_1_bot {
			width: 1360px;
			margin: 0 auto;
			display: flex;
			flex-direction: column;

			.prod-item {
				width: 100%;
				display: flex;
				flex-direction: column;

				.prode-type {
					width: 100%;
					font-size: 26px;
					margin-top: 40px;
					margin-bottom: 15px;
					color: #303030;
					text-align: left;
				}

				.prode-msg {
					width: 600px;
					font-size: 23px;
					color: #747474;
					text-align: left;
					margin-bottom: 30px;
				}

				.device-list {
					width: 100%;
					display: flex;
					flex-wrap: wrap;

					.device-class {
						width: 270px;
						display: flex;
						flex-direction: column;
						margin-right: 20px;
						margin-bottom: 20px;
						cursor: pointer;

						.device-img {
							width: 270px;
							height: 310px;
							overflow: hidden;

							img {
								width: 270px;
								height: 310px;
								object-fit: cover; // 保持图片缩放时候不变形
							}
						}



						.device-name {
							width: 100%;
							height: 50px;
							border-radius: 5px;
							background-color: #EDEDED;
							display: flex;
							align-items: center;
							justify-content: center;
							color: #434343;
							font-size: 18px;
						}

					}

					.device-class:hover {
						.device-img {
							img {
								transition: all 0.8s; //设置动画执行的时间为0.6s
								transform: scale(1.1); // 设置图片放大倍数
							}
						}

						.device-name {
							background-color: darkorange;
							color: #ffffff;
						}
					}
				}




			}




		}
	}
</style>